import api from '../../utils/api'


export function  postponeManage(params) {
    return api(`/ca/onlc/oss/manage/postpone?orderId=${params.orderId}`, {
        method: 'POST',
        // params: { ...params }
    })
}

/** 证书签发回调 */
export function activeCert (params) {
  return api(`/ca/onlc/oss/manage/postpone-success?orderId=${params.orderId}`, {
    method: 'POST',
    //  params: {
    //   ...data
    // }
  })
}

export function queryOrderInfo(params) {
  return api('/ca/onlc/order/get-by-serialNumber', {
      method: 'GET',
      params: params
  })
}

export function sealPostpone(params) {
  return api('/ca/onlc/oss/manage/seal-postpone', {
      method: 'POST',
      params: params
  })
}