<template>
  <div class="operaHome">
    <h1 style="margin-bottom: 25px">在线延期</h1>
    <el-alert
      type="warning"
      class="alert"
      show-icon
      title="温馨提示：您正在进行延期操作，延期后PIN码不变，请妥善保管好您的数字证书和介质key。"
    ></el-alert>
    <div class="operaHome_top">
      <h1>
        <div class="titleIcon"></div>
        证书基本信息
      </h1>
      <div style="margin-left: 8px">
        <el-descriptions title="" column="3">
          <el-descriptions-item label="证书名称">{{
            certInfo.commonName
          }}</el-descriptions-item>
          <el-descriptions-item label="当前证书有效截止日期">{{
            certInfo?.jsonExt.YCertNotAfter
          }}</el-descriptions-item>
          <el-descriptions-item label="延期后证书有效截止日期">{{
            certInfo.notAfter
          }}</el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div class="operaHome_footer operaHome_top">
      <h1>
        <div class="titleIcon"></div>
        介质信息
      </h1>

      <el-form :model="postponeForm" ref=" postponeForm" class="el-form">
        <el-form-item
          class="flex"
          prop="key"
          label="介质Key"
          :rules="[
            {
              required: true,
              message: 'Key不能为空',
            },
          ]"
        >
          <el-select class="el-input" v-model="postponeForm.key">
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item"
              :value="item"
            />
          </el-select>
          <el-button 
          class="el-btn" 
          type="primary" 
          @click="getFacilityMessage"
          :loading ='infoLoading'
          >
            获取设备信息
          </el-button>
        </el-form-item>
        <el-form-item
          class="flex"
          prop="pin"
          label="介质PIN"
          :rules="[
            {
              required: true,
              message: '请输入正确的PIN',
            },
          ]"
        >
          <el-input
            class="el-input"
            v-model="postponeForm.pin"
            placeholder="请输入"
            show-password
          ></el-input>
          <el-button
            :class="postponeForm.pin ? 'el-btn' : 'btn'"
            type="primary"
            @click.prevent="verifyPin"
            :disabled="!postponeForm.pin"
            >校验</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            :disabled="!flag"
            :class="!flag ? 'btnSubmit' : 'el-submit'"
            type="primary"
            @click="submitForm"
            >立即延期</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-dialog :visible.sync="writeVisible">
      <div class="progressVisible">
        <div>
          <img :src="imgSrt" alt="" />
        </div>
        <div class="progress">
          <span class="progress_title">正在延期，请稍等...</span>
          <el-progress :percentage="percentage"></el-progress>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PKIDriver, { getProBySubject } from "../../utils/pki-driver";
import imgSrt from "../../assets/progess.png";
import {
  postponeManage,
  queryOrderInfo,
  activeCert,
  sealPostpone,
} from "./serve";
import { getGoodsList } from "../../utils/public-instrument";
export default {
  data() {
    return {
      CN: "CN项",
      startDate: "2023-08-01 13:21:22",
      endDate: "2023-08-31  13:21:22",
      postponeForm: {
        key: "",
        pin: "",
      },
      flag: false,
      visible: false,
      success: true,
      writeVisible: false,
      serialNumber: "",
      options: [],
      imgSrt: imgSrt,
      percentage: 0,
      DeviceSN: "",
      orderId: "",
      certInfo: {},
      isSeal: false,
      YCertNotAfter:'',
      infoLoading:false
    };
  },
  computed: {},
  watch: {},

  methods: {
    //获取证书
    getFacilityMessage() {
      this.infoLoading=true
      const pkiDriver = this.getPkiDriver();
      pkiDriver.getCertInfo().then(async (res) => {
        this.infoLoading=false
        this.serialNumber = res.SerialNumber;
        this.DeviceSN = res.DeviceSN;
        const cn = getProBySubject(res.Subject, "cn");
        this.postponeForm.key = cn;

        this.options.push(cn);
        const response = await queryOrderInfo({
          serialNumber: res.SerialNumber,
        });
        if (response.data && response.data.orderId) {
          this.orderId = response.data.orderId;
          this.isSeal = response.data.specifications.SEAL;
        } else {
          this.$message({
            message: `此证书没有延期的订单`,
            type: "error",
          });
        }
      }).catch((error)=>{
        this.infoLoading=false
        this.$message({
          message: error.errorMsg,
          type: "error",
        });
      });
    },
    getPkiDriver() {
      if (this.pkiDriver) {
        return this.pkiDriver;
      }
      this.pkiDriver = new PKIDriver();
      return this.pkiDriver;
    },
    verifyPin() {
      this.flag = true;
      const sUserPin = this.postponeForm.pin;
      const sDeviceSN = this.DeviceSN
      const pkiDriver = this.getPkiDriver();
      pkiDriver
        .verifyUserPin(sDeviceSN, sUserPin)
        .then((res) => {
          res && (this.flag = true);
          this.$message({
            message: `校验通过`,
            type: "success",
          });
        })
        .catch((error) => {
          this.$message({
            message: `${error.errorMsg}有误,请检查`,
            type: "error",
          });
          this.flag = false;
        });
    },
    submitForm() {
      this.writeVisible = true;
      this.addWriteListner();
      this.onWriteCert();
    },
    addWriteListner() {
      this.writeSuccess = false;
      this.percentage = 0;
      this.timer = setInterval(() => {
        if (this.percentage == 95 && !this.writeSuccess) {
          return;
        }
        if (this.writeSuccess) {
          this.percentage += 5;
        } else {
          this.percentage += 2;
        }
        if (this.percentage >= 100) {
          clearInterval(this.timer);
          // this.onWriteSuceess();
        }
      }, 200);
    },
    onWriteSuceess() {

      this.writeVisible = false;
      this.$confirm("您的证书延期成功", "延期成功", {
        confirmButtonText: "查看订单",
        cancelButtonText: "继续延期",
        type: "success",
      })
        .then(() => {
          this.$router.replace({
            name: "orderForm",
          });
        })
        .catch(() => {
          this.$router.replace("/postpone/online");
        });
    },
    onWriteFail(err) {
      this.writeVisible = false;
      this.$confirm(err.message, '延期失败', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      });
    },
    onWriteCert() {
      const containerName = "conNJYZT";
      const sUserPin = this.postponeForm.pin;
      const sDeviceSN = this.DeviceSN
      const pkiDriver = this.getPkiDriver();
      let encryptionCert, encryptionPrivateKey, signatureCert;
      new PKIDriver()
        .getAllDeviceSN()
        .then( async(res) => {
          const { sDeviceSNs } = res;
          if (!sDeviceSNs) {
            this.$notify.error({
              title: "请插入介质Key",
            });
            return;
          }
        }).then(()=>{
          
         return new PKIDriver().verifyUserPin(sDeviceSN, sUserPin)
        })
        .then(()=>{
         // 向服务器签发证书
         return postponeManage({ orderId: this.orderId });
        })
        .then((res) => {
          const { data } = res;
          encryptionCert = data.encCertificate;
          encryptionPrivateKey = data.encryptionPrivateKey;
          signatureCert = data.sigCertificate;
          // 导入签名证书
          return new PKIDriver().importSignCert(
            this.DeviceSN,
            containerName,
            signatureCert
          );
        })
        .then((res) => {
          // 导入加密证书
          return new PKIDriver().importEncCert(
            this.DeviceSN,
            containerName,
            encryptionCert,
            encryptionPrivateKey
          );
        })
        .then(() => {
          if (this.isSeal) {
            this.onWriteSeal();
          }
        })
        .then((res) => {
          // 激活证书
          return activeCert({ orderId: this.orderId });
        })
        .then((res) => {
          this.percentage = 100;
          this.onWriteSuceess();
        })
        .catch((err) => {
          if (err.errMsg) {
            this.$notify.error({
              title: "证书签发失败",
              message: err.errMsg,
            });
          }
          this.onWriteFail({ message: err.errorMsg || err.message || err.data.message });
        });
    },
    async handleWriteSeal(sealfiles,index){
      if(sealfiles?.length<=index){
        return
      }
      try{
        const res = await new PKIDriver().readFile(
                this.DeviceSN,
                sealfiles[index],
              );
              const { sContent } = res;
              if (sContent.startsWith("MI")) {
                const sealData = await sealPostpone({
                  orderId: this.orderId,
                  stampData: sContent,
                });
                const {data} = sealData
                await new PKIDriver().writeFile(
                  this.DeviceSN,
                  sealfiles[index],
                  data
                );
              }
        this.handleWriteSeal(sealfiles,index+1)

      }catch(err){
        if (err.errMsg) {
          this.$notify.error({
            title: "印章签发失败",
            message: err.errMsg,
          });
        }
      }
         

    },
    async onWriteSeal() {
      try {
        const files = await new PKIDriver().getAllFiles(this.DeviceSN);
        const {sFiles} = files
        const fileArr = sFiles.split(";");
        const sealArr = [
          "sealSpec",
          "sealSpec_1",
          "sealSpec_2",
          "sealSpec_3",
          "sealSpec_4",
          "sealSpec_5",
        ];
        
        const sealfiles = fileArr.filter(file=>sealArr.includes(file))
        this.handleWriteSeal(sealfiles,0)
        // sealfiles.forEach( async(item)=>{
        //   const res = await new PKIDriver().readFile(
        //         this.DeviceSN,
        //         item,
        //       );
        //       const { sContent } = res;
        //       if (sContent.startsWith("MI")) {
        //         const sealData = await sealPostpone({
        //           orderId: this.orderId,
        //           stampData: sContent,
        //         });
        //         const {data} = sealData
        //         await new PKIDriver().writeFile(
        //           this.DeviceSN,
        //           item,
        //           data
        //         );
        //       }
        // })


      } catch (err) {
        if (err.errMsg) {
          this.$notify.error({
            title: "印章签发失败",
            message: err.errMsg,
          });
        }
      }
    },
    postpone(orderId) {
      postponeManage({ orderId: orderId }).then((res) => {
        this.certInfo = res.data;
      });
    },
  },
  created() {
    const info = getGoodsList();
    const id = this.$route.query.orderId || info.id;
    this.postpone(id);
  },
  mounted() {
   
  },
  components: {},
};
</script>
<style scoped>
:deep(.el-input__inner) {
  height: 31px;
}
:deep(.el-button--primary) {
  background-color: #2086fa ;
  border-color: #2086fa ;
}

:deep(.el-alert--warning.is-light){
  background: #fff7eb ;
  border-color: #fff7eb ;
}
.operaHome {
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 62.5vw;
}
h1 {
  height: 19px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #313438;
  line-height: 42px;
}

.operaHome_top {
  width: 56%;
  margin-left: 50px;
  padding: 0;
  min-width: 700px;
}
.operaHome_top h1 {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #373a3e;
  margin-bottom: 20px;
}

.el-submit {
  float: left;
  margin-left: 75px;
  width: 136px;
  height: 32px;
  padding: 9px 20px;
}
.titleIcon {
  width: 3px;
  height: 16px;
  margin-right: 5px;
  background: #0077ff;
}
.progressVisible {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress {
  width: 720px;
  border-radius: 2px;
  margin-top: -17px;
}
.progress_title {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #313438;
  float: left;
  margin-left: 2px;
}
.flex {
  display: flex;
}
.el-input {
  width: 400px;
  height: 32px;
  border-radius: 2px;
}
.el-form {
  line-height: 0;
  margin-left: 10px;
}
.el-btn {
  width: 116px;
  height: 32px;
  margin-left: 7px;
  padding: 8px 17px;
}

.btn {
  background: #f2f6fa;
  border: 1px solid #dcdfe6;
  color: #babcbf;
  font-weight: 500;
  padding: 9px 20px;
  font-size: 14px;
  border-radius: 4px;
  width: 116px;
  height: 32px;
  margin-left: 7px;
}
.btnSubmit {
  float: left;
  margin-left: 75px;
  width: 136px;
  height: 32px;
  background: #f2f6fa;
  border: 1px solid #dcdfe6;
  color: #babcbf;
  font-weight: 500;
  padding: 9px 20px;
  font-size: 14px;
  border-radius: 4px;
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  color: #c0c4cc;
  cursor: not-allowed;
  background-image: none;
  /* background-color: #FFF; */
  /* border-color: #EBEEF5; */
  background: #f2f6fa;
}
.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
  /* color: #FFF; */
  /* background-color: #a0cfff; */
  border-color: rgb(242, 241, 238);
}

.alert {
  width: 55%;
  text-align: center;
  letter-spacing: 3px;
  margin-bottom: 25px;
  height: 38px;
  margin-top: 5px;
  min-width: 700px;
}
.marginLeft {
  margin-left: 98px;
}
@media screen and(max-height:750px) {
  .marginLeft {
    margin-left: 38px;
  }
}
</style>